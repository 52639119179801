html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
html {
	height: 100%;
	position: relative;
}
* {
	margin: 0;
	padding: 0;
}
body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 100%;
	min-height: 100%;
	position: relative;
	color: #575757;
	line-height: 145%;
	background-color: #FFFAFA;
}
a {
	color: #3399cc;
	font-weight: bold;
	text-decoration: none;
	-webkit-transition: opacity 0.4s ease-out;
	transition: opacity 0.4s ease-out;
	cursor: pointer;
}
a:hover {
	color: #66ccff;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
.nowrap {
	white-space: nowrap;
}
.group {
	clear: both;
	display: block;
}
#featured {
	font-family: 'Merriweather', serif;
	font-size: 1.4em;
	line-height: 1.6;
	margin: 100px auto 30px;
	width: 48.4375%;
	float: none;
}
#top {
	height: 90px;
}
header {
	background: rgba(255, 255, 255, 0.90);
	border-bottom: 1px solid rgba(0, 0, 0, 0.35);
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
	height: 88px;
	margin-top: -20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
}
header nav {
	max-width: 1060px;
	margin: 0 auto;
	height: 88px;
	position: relative;
	box-sizing: border-box;
	width: 77.7778%;
}
ol,
ul {
	list-style: none;
}
header nav li {
	float: left;
	width: 33.33333333333333%;
}
header nav li a {
	border-left: 3px solid rgba(0, 0, 0, 0.1);
	box-shadow: inset 1px 0px 0px rgba(255, 255, 255, 0.5);
	color: #3399cc;
	display: block;
	height: 88px;
	line-height: 115px;
	padding: 0 20px;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
header nav li:last-child a {
	border-right: 3px solid rgba(0, 0, 0, 0.1);
}
.about {
	text-align: center;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.45);
	float: none;
}
.about h1,
.about h2 {
	display: block;
}
.name {
	text-align: center;
	margin: 0 0 10px 0;
}
.lname {
	color: #3399cc;
}
.bio {
	text-align: justify;
	margin: 0 0 24px 0;
}
.about .social {
	color: rgba(57, 57, 57, 0.3);
}
.projects {
	width: 976px;
	position: relative;
	margin: auto;
	float: none;
}
.projects article {
	width: 100%;
	border-top: 1px solid #e9e9e9;
	padding: 40px 0 20px 0;
	overflow: hidden;
}
.projects article h2 {
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 135%;
	margin-bottom: 10px;
}
.projects article img {
	width: 298px;
	margin-right: 33px;
	float: left;
	box-sizing: border-box;
}
.projects article p, .projects article ul li, .projects article ul li ul li {
	font-size: 1.2rem;
	font-weight: 300;
	margin: 0 0 20px 0;
	width: 64.85655737704918%;
	float: left;
}
.projects article ul li {
		margin: 0;
}
.projects article ul li ul {
	margin-top: 1rem;
}
.projects article ul li ul li {
		margin-left: 1rem;
}
.projects article ul li:last-child {
	margin-bottom: 1rem;
}
footer {
	background: #191919;
	padding: 82px 0;
	float: none;
}
footer a {
	color: #999999;
	display: block;
	text-align: center;
	line-height: 60px;
	margin: 0 auto;
	font-size: 3em;
}
footer a:hover {
	color: #3399cc;
}
@media screen and (min-width: 1500px) {
	.about {
		margin-left: auto;
		margin-right: auto;
		max-width: 800px;
	}
}
@media screen and (max-width: 960px) {
	#featured {
		font-size: 1.2rem;
		width: 65.625%;
		margin: 78px 0 76px 0;
		margin-right: 17.1875%;
		margin-left: 17.1875%;
	}
}
@media screen and (max-width: 640px) {
	header nav {
		width: 100%;
	}
	#featured {
		font-size: 1rem;
		width: 72.34375%;
		margin: 78px 0 76px 0;
		margin-right: 13.828125%;
		margin-left: 13.828125%;
	}
	.projects, .class-projects {
		width: 95%;
	}
	.projects article {
		border-top: 1px solid #e9e9e9;
		padding: 20px 0 10px 0;
	}
	.projects article h2 {
		padding: 0;
	}
	.projects article img {
		margin: 0;
	}
	.projects article p, .projects article ul li, .projects article ul li ul li  {
		font-size: 1rem;
		font-weight: 300;
		margin-top: 10px;
		width: 95%;
	}
	.projects article ul {
		float: left;
		margin-top: 10px;
	}
	.projects article ul li {
			margin: 0;
	}
	.projects article ul li ul {
		margin-top: 1rem;
	}
	.projects article ul li ul li {
			margin-left: 1rem;
	}
	.projects article ul li:last-child {
		margin-bottom: 1rem;
	}
	footer a {
		font-size: 1em;
	}
}

@media screen and (max-width: 420px) {
	#featured {
		font-size: 0.85em;
		width: 94%;
		margin: 20px 0 20px 0;
		margin-right: 3%;
		margin-left: 3%;
	}
}
